<template>
  <div
    style="padding: 0; flex: 1; position: relative"
    @click="inPopup && isInputDisabled && notifyWithToast()"
  >
    <div>
      <div
        class="top-item"
        :class="{
          'winning-team':
            winningTeam && winningTeam.id === props.game.awayTeam?.id,
          'losing-team':
            winningTeam && winningTeam.id !== props.game.awayTeam?.id,
        }"
        :style="{ borderColor: awayBorderColor }"
      >
        <div class="team-names-container">
          <div class="team-name">
            <!-- <transition name="fade" mode="out-in"> -->
              <div class="main-name" v-if="props.game.awayTeam?.name">
                {{ props.game.awayTeam.name }}
              </div>
              <ion-row>
                <ion-col class="ion-no-padding">
                  <div class="team-standings" v-if="props.game.awayTeam?.name">
                    {{ awayTeamStandings }}
                  </div>
                  
                </ion-col>
                <ion-col class="ion-no-padding">
                  <div class="prev-game-name" v-if="props.game.away_team_freetext_formatted">
                    {{ props.game.away_team_freetext_formatted_game_num }}
                  </div>
                </ion-col>
              </ion-row>
              <!-- <pre>{{ props.game }}</pre> -->
              
            <!-- </transition> -->

            <!-- <span
              style="color: var(--ion-color-medium)"
              v-if="props.game.awayTeam && preferenceStore.showTeamIDs"
            >
              | {{ props.game.awayTeam?.id }}
            </span> -->
          </div>

     
        </div>
        <div class="team-score">
          {{ props.game.away_team_score }}
        </div>
      </div>
      <div
        class="bottom-item"
        :class="{
          'winning-team':
            winningTeam && winningTeam.id === props.game.homeTeam?.id,
          'losing-team':
            winningTeam && winningTeam.id !== props.game.homeTeam?.id,
        }"
        :style="{ borderColor: homeBorderColor }"
      >
        <div class="team-names-container">
          <div class="team-name">
            <!-- <transition name="fade" mode="out-in"> -->
              <div class="main-name" v-if="props.game.homeTeam?.name">
                <span v-if="leagueStore.league?.show_home_away === 1"> at</span>
                {{ props.game.homeTeam.name }}
              </div>
              <ion-row>
                <ion-col class="ion-no-padding">
                  <div class="team-standings" v-if="props.game.homeTeam?.name">
                    {{ homeTeamStandings }}
                  </div>
                </ion-col>
                <ion-col class="ion-no-padding">
                  <div class="prev-game-name" v-if="props.game.away_team_freetext_formatted">
                    {{ props.game.home_team_freetext_formatted_game_num }}
                  </div>
                </ion-col>
              </ion-row>
            <!-- </transition> -->
            <!-- <span
              style="color: var(--ion-color-medium)"
              v-if="props.game.homeTeam && preferenceStore.showTeamIDs"
              >| {{ props.game.homeTeam?.id }}</span
            > -->
          </div>
          
        </div>
        <div class="team-score">
          {{ props.game.home_team_score }}
        </div>
      </div>
    </div>
    <div class="postponed" v-if="props.game.game_status === 'postponed'">
      <div class="postponed-rotate">
        <div class="postponed-title">Postponed</div>
        <div class="postponed-reason">
          due to {{ props.game.reschedule_reason }}
        </div>
      </div>
    </div>
    <div class="best-of-bar" v-if="props.game.best_of > 1">
      Best of {{ props.game.best_of }} Game #1
    </div>
    <div class="best-of-bar" v-if="props.game.part_of">
      Best of {{ findPartOfGames(props.game.part_of) }} - Game #{{
        props.game.part_of_order
      }}
    </div>
  </div>
</template>
<script setup>
import { defineComponent, defineProps, computed, onMounted } from "vue";

import { useLeagueStore } from "@/stores/leagueStore";
//   import { useAuthStore } from "@/stores/authStore";
import { useGameStore } from "@/stores/gameStore";
import { IonRow, IonCol } from "@ionic/vue";

//   import { useReportGameStore } from "@/stores/reportGameStore";
// import { usePreferenceStore } from "@/stores/preferenceStore";
// import { parse, format } from "date-fns";

import uniqolor from "uniqolor";

defineComponent({
  name: "GameCardContent",
});
const props = defineProps([
  "game",
  "isGameSelected",
  "inPopup",
  "convenorPopup",
  "clearScoreCount",
  "showReportButton",
  "reportScoreCount",
]);
//   const emit = defineEmits(["resetGame", "showReportButton", "hideReportButton"]);
const leagueStore = useLeagueStore();
//   const authStore = useAuthStore();
const gameStore = useGameStore();
//   const reportStore = useReportGameStore();
// const preferenceStore = usePreferenceStore();

// const awayTeamDisplay = ref("");
// const homeTeamDisplay = ref("");

//   const awayScore = ref();
//   const homeScore = ref();

//   const reportedHomeScore = ref(null)
//   const reportedAwayScore = ref(null)

const findPartOfGames = (game_part_of) => {
  const games = gameStore.games;
  const partOfGames = games.find((game) => game.id === game_part_of);
  if (partOfGames) return partOfGames.best_of;
};

const awayBorderColor = computed(() => {
  return (
    props.game.awayTeam?.color ||
    uniqolor(
      props.game.awayTeam?.name || props.game.away_team_freetext_formatted
    ).color
  );
});

const homeBorderColor = computed(() => {
  return (
    props.game.homeTeam?.color ||
    uniqolor(
      props.game.homeTeam?.name || props.game.home_team_freetext_formatted
    ).color
  );
});

const winningTeam = computed(() => {
  if (props.game.score_reported === 1) {
    if (props.game.away_team_score > props.game.home_team_score) {
      return props.game.awayTeam;
    } else if (props.game.home_team_score > props.game.away_team_score) {
      return props.game.homeTeam;
    }
  }
  return null;
});

// Function to cycle through team names
// function cycleTeamNames() {
//   // const formatGameNum = (text) => {
//   //   if (!text) return "";
//   //   return text
//   //     .replace("Winner of Game ", "W G#")
//   //     .replace("Loser of Game ", "L G#");
//   // };

//   // const formatDateInfo = (text) => {
//   //   if (!text) return "";

//   //   if (/winner of|loser of/i.test(text)) {
//   //     let prefix = /winner/i.test(text) ? "W @" : "L @";
//   //     let dateTimeMatch = text.match(
//   //       /(\d{1,2}:\d{2} (AM|PM)) ON (\d{2}-\d{2}) - (\w+)/i
//   //     );
//   //     if (dateTimeMatch) {
//   //       let time = dateTimeMatch[1]; // Capture the time
//   //       let date = dateTimeMatch[3]; // Capture the date
//   //       let ending = dateTimeMatch[4]; // Capture the ending part

//   //       let currentYear = new Date().getFullYear();
//   //       let formattedDateTime = `${time} ON ${date}-${currentYear}`;

//   //       // Attempt to parse the date
//   //       try {
//   //         let parsedDate = parse(
//   //           formattedDateTime,
//   //           `h:mm aa 'ON' MM-dd-yyyy`,
//   //           new Date()
//   //         );
//   //         // Append the captured ending part back to the formatted output
//   //         return `${prefix} ${format(
//   //           parsedDate,
//   //           "h:mm aaa EEE, MMM d"
//   //         )} - ${ending}`;
//   //       } catch (error) {
//   //         console.error("Date parsing error:", error.message);
//   //         return text; // Fallback to original text if parsing fails
//   //       }
//   //     }
//   //   } else if (/placed/i.test(text)) {
//   //     return text.replace(/placed (\d+)(st|nd|rd|th) in (\w+)/i, "$1$2 in $3");
//   //   }
//   //   return text; // Fallback for unhandled formats
//   // };
  
//     // console.log('props.game', props.game)
//     // if (props.game.away_team_freetext && props.game.away_team_freetext.includes("placed")) {
//     //   const match = props.game.away_team_freetext.match(/placed (\d+)/i);
//     //   if (match) {
//     //     console.log('Number from away_team_freetext:', match[1]); // This logs the extracted number
//     //   }
//     // }

//     // if (props.game.home_team_freetext && props.game.home_team_freetext.includes("placed")) {
//     //   const match = props.game.home_team_freetext.match(/placed (\d+)/i);
//     //   if (match) {
//     //     console.log('Number from home_team_freetext:', match[1]); // This logs the extracted number
//     //   }
//     // }

//     const awayNames = [
//       props.game.awayTeam?.name
//       // props.game.awayTeam?.name && props.game.away_team_freetext_formatted_game_num
//       //   ? `${props.game.awayTeam.name} - ${props.game.away_team_freetext_formatted_game_num}`
//       //   : null
//     ].filter(Boolean);

//     const homeNames = [
//       props.game.homeTeam?.name
//       // props.game.homeTeam?.name && props.game.home_team_freetext_formatted_game_num
//       //   ? `${props.game.homeTeam.name} - ${props.game.home_team_freetext_formatted_game_num}`
//       //   : null
//     ].filter(Boolean);

//   awayTeamDisplay.value = awayNames[0];
//   homeTeamDisplay.value = homeNames[0];

//   let awayIndex = 1;
//   let homeIndex = 1;

//   setInterval(() => {
//     awayTeamDisplay.value = awayNames[awayIndex++ % awayNames.length];
//     homeTeamDisplay.value = homeNames[homeIndex++ % homeNames.length];
//   }, 10000); // Change every 10 seconds
// }

onMounted(() => {
  // cycleTeamNames();
});

console.log(props.game.awayTeam);

const awayTeamStandings = computed(() => {
  const team = props.game.awayTeam;
  const mode = props.game.mode;

  const standings = team.standings.find((standing) => standing.mode === mode);

  return standings
    ? `${mode}: (${standings.wins || 0}-${standings.losses || 0}-${
        standings.ties || 0
      })`
    : "";
});

const homeTeamStandings = computed(() => {
  const team = props.game.homeTeam;
  const mode = props.game.mode;

  const standings = team.standings.find((standing) => standing.mode === mode);

  return standings
    ? `${mode}: (${standings.wins || 0}-${standings.losses || 0}-${
        standings.ties || 0
      })`
    : "";
});

// Function to validate score input
//   function isValidScore(score) {
//     const parsedScore = parseInt(score);
//     return !isNaN(parsedScore) && parsedScore >= 0;
//   }
</script>
<style scoped>
.top-item,
.bottom-item {
  display: flex;
  align-items: center; /* Center items vertically */
  border-left-style: solid;
  border-width: 0.75rem;
}

.team-names-container {
  flex-grow: 1; /* Take up remaining space */
  display: flex;
  flex-direction: column;
  height: 70px;
  padding-right: 10px;
  padding-left: 10px;
  justify-content: center; /* Center content vertically */
}

/* .team-names, */
.team-standings {
  margin: 0; /* Remove default margins */
  display: flex;
  /* justify-content: flex-end; Align content to the left */
  align-items: center; /* Center content vertically */
  text-align: left; /* Ensure text within is aligned left */
  width: 100%; /* Ensure it takes up all available space for alignment */
}

.team-names {
  font-size: 0.9rem;

  text-transform: uppercase;
  font-weight: 700;
}

.main-name {
  font-size: 13.5px;
  text-transform: uppercase;
  font-weight: 500;
  padding-bottom: 3px;
}

.prev-game-name {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
}

.prev-game-name { 
  text-align: right;
}

.team-standings {
  font-size: 10px;
}

.team-score {
  flex-shrink: 0;
  width: 50px; /* Fixed width for the score section */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  text-align: center;
  font-size: 1.5rem;
  background: #222;
  height: 70px;

  font-weight: 700;
  border: 1px solid #131313;
}

.winning-team {
  background: #011904;
  color: #7fd77a;
}

.losing-team {
  background: #170101;
  color: #d77a7a;
}

.best-of-bar {
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  padding: 2px 5px;
  font-weight: 700;
  color: #fff;
  background: var(--ion-color-primary);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.postponed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
  vertical-align: middle;
  color: #df3030;
  display: flex; /* Make it a flex container */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  flex-direction: column; /* Stack children vertically */
  text-align: center;
}

.postponed-title {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
}

.postponed-reason {
  font-weight: 700;
  text-transform: uppercase;
}
</style>
