<template>
  <div>
    <slot v-if="rampReady"></slot>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onUnmounted,
  watch,
  provide
} from 'vue';
import {
  useRoute
} from 'vue-router';
import {
  defineProps
} from 'vue';

import { useAdStore } from '@/stores/adStore';

const adStore = useAdStore();

// Props
const props = defineProps({
  pwUnits: Array,
  forcePath: String,
  PUB_ID: String,
  WEBSITE_ID: String,
});

// Reactive state
const rampComponentLoaded = ref(false);
const initialLoadComplete = ref(false);
const rampReady = ref(false);
const route = useRoute();
const unitsAdded = ref(false);

provide('rampReady', rampReady);

// Function to add ad units
const addUnits = () => {
  if (unitsAdded.value) return;
  window.ramp.que.push(() => {
    window.ramp.addUnits(props.pwUnits)
      .catch(e => console.warn('Error adding units:', e))
      .finally(() => {
        window.ramp.displayUnits();
        rampReady.value = true;
        unitsAdded.value = true;
      });
  });
};

// Cleanup function to remove all ad units
const cleanUp = () => {
  if (!initialLoadComplete.value) return; // Prevent cleanup on initial load
  if (!window.ramp?.settings?.slots) return;

  window.ramp.que.push(() => {
    // Get all slot names, including med_Rect
    const allSlots = Object.keys(window.ramp.settings.slots);
    console.log('Slots to be destroyed:', allSlots);
    
    // Destroy all units
    window.ramp.destroyUnits(allSlots)
      .then(() => {
        console.log('All units destroyed successfully');
        unitsAdded.value = false; // Reset the flag
        rampReady.value = false; // Reset rampReady
        addUnits(); // Re-add units after destruction
      })
      .catch(e => console.warn('Error destroying units:', e));
  });
};


watch(() => adStore.shouldRefreshAds, (shouldRefresh) => {
  if (shouldRefresh) {
    cleanUp();
    adStore.resetAdRefresh();
  }
});

onMounted(() => {
  if (!props.PUB_ID || !props.WEBSITE_ID) {
    console.log('Missing Publisher Id and Website Id');
    return;
  }
  if (!rampComponentLoaded.value) {
    console.log('fired')
    rampComponentLoaded.value = true;
    window.ramp = window.ramp || {};
    window.ramp.que = window.ramp.que || [];
    window.ramp.passiveMode = true;
    window.ramp.forcePath = props.forcePath || window.ramp.forcePath;
    // Load the Ramp configuration script
    const configScript = document.createElement('script');
    configScript.src = `https://cdn.intergient.com/${props.PUB_ID}/${props.WEBSITE_ID}/ramp.js`;
    document.head.appendChild(configScript);
    configScript.onload = () => {
      addUnits();
      initialLoadComplete.value = true;
    };
  }
});

// Cleanup function to remove units on component unmount
onUnmounted(() => {
  cleanUp();
});

watch(
  () => [props.forcePath, route.path],
  ([newForcePath, newPath]) => {
    if (!rampComponentLoaded.value) return;
    let currentPath = newForcePath || newPath;
    window.ramp.que.push(() => {
      if (currentPath === '/') {
        window.ramp.setPath('/');
        window.ramp.forcePath('all-rails-on')
      } else {
        window.ramp.setPath('ROS');
      }
      cleanUp(); // This will now destroy all units, including med_Rect
    });
  }
);
</script>