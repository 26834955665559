<template>
  <ion-app>
    <!-- style="top: 100px;" -->
    <div 
      class="something-above"
      v-if="isPlatform('ios') || isPlatform('android')"
      v-on:click="addToHome">Add SPC as an app on your phone! Tap here to learn how.</div>
    <div 
      class="something-above"
      v-else>Bookmark SPC on your computer so you never lose it!.</div>
    <ion-split-pane
      content-id="main-content"
      :when="
        sideMenuBlacklist.includes(route.name)
          ? '(min-width: 10000px)'
          : '(min-width: 1000px)'
      "
    >
      <side-menu />
      <ion-router-outlet id="main-content" />
    </ion-split-pane>
    <RampView
      :pwUnits="[
        { type: 'corner_ad_video' },
        { type: 'in_content' },
        { type: 'sticky_sidebar' },
        // { type: 'bottom_rail' },
        // { type: 'left_rail' },
        // { type: 'right_rail' },
        { type: 'leaderboard_atf', selectorId: 'pwLeaderboardAtf' },
        { type: 'leaderboard_atf', selectorId: 'pwLeaderboardBtf' },
        { type: 'med_rect_atf', selectorId: 'medRectAtf' },
        { type: 'med_rect_atf', selectorId: 'medRectAtf2' },
        { type: 'leaderboard_atf', selectorId: 'pwDashboard' },
        { type: 'leaderboard_btf', selectorId: 'pwDashboard2' },
        { type: 'leaderboard_atf', selectorId: 'inlineRectAtf'},
        { type: 'leaderboard_btf', selectorId: 'inlineRectAtf0'},
        { type: 'leaderboard_btf', selectorId: 'inlineRectAtf1'},
        { type: 'leaderboard_btf', selectorId: 'inlineRectAtf2'},
        { type: 'leaderboard_btf', selectorId: 'inlineRectAtf3'},
        { type: 'leaderboard_btf', selectorId: 'inlineRectAtf4'},
        { type: 'leaderboard_btf', selectorId: 'inlineRectAtf5'},
        { type: 'leaderboard_btf', selectorId: 'inlineRectAtf6'},
        { type: 'leaderboard_btf', selectorId: 'inlineRectAtf7'},
        { type: 'leaderboard_btf', selectorId: 'inlineRectAtf8'},
        { type: 'leaderboard_btf', selectorId: 'inlineRectAtf9'},
        { type: 'leaderboard_btf', selectorId: 'inlineRectAtf10'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf0'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf1'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf2'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf3'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf4'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf5'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf6'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf7'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf8'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf9'},
        { type: 'med_rect_atf', selectorId: 'mobileRectAtf10'},
        { type: 'med_rect_atf', selectorId: 'gameCardPopup'},
        { type: 'med_rect_atf', selectorId: 'leagueSwitchModal'},
        { type: 'med_rect_atf', selectorId: 'announcementComponent0'},
        { type: 'med_rect_atf', selectorId: 'announcementComponent1'},
        { type: 'med_rect_atf', selectorId: 'announcementComponent2'},
        { type: 'med_rect_atf', selectorId: 'announcementComponent3'},
        { type: 'med_rect_atf', selectorId: 'announcementComponent4'},
        { type: 'leaderboard_atf', selectorId: 'adDemos'},
        { type: 'leaderboard_atf', selectorId: 'diamondsByCity'},
        { type: 'leaderboard_atf', selectorId: 'diamondsPage'},
        { type: 'med_rect_atf', selectorId: 'diamondsPage0'},
        { type: 'med_rect_atf', selectorId: 'diamondsPage1'},
        { type: 'med_rect_atf', selectorId: 'diamondsPage3'},
        { type: 'med_rect_atf', selectorId: 'diamondsPage4'},
        { type: 'med_rect_atf', selectorId: 'diamondsPage5'},
        { type: 'med_rect_atf', selectorId: 'diamondsPage6'},
        { type: 'med_rect_atf', selectorId: 'diamondsPage7'},
        { type: 'med_rect_atf', selectorId: 'diamondsPage8'},
        { type: 'leaderboard_atf', selectorId: 'postsPage1'},
        { type: 'leaderboard_btf', selectorId: 'postsPage2'},
        { type: 'leaderboard_btf', selectorId: 'postsPage3'},
        { type: 'leaderboard_btf', selectorId: 'postsPage4'},
        { type: 'leaderboard_btf', selectorId: 'postsPage5'},
        { type: 'leaderboard_btf', selectorId: 'postsPage6'},
        { type: 'leaderboard_btf', selectorId: 'postsPage7'},
        { type: 'leaderboard_atf', selectorId: 'eventDirectory1'},
        { type: 'leaderboard_btf', selectorId: 'eventDirectory2'},
        { type: 'leaderboard_btf', selectorId: 'eventDirectory3'},
        { type: 'leaderboard_btf', selectorId: 'eventDirectory4'},
        { type: 'leaderboard_btf', selectorId: 'eventDirectory5'},
        { type: 'leaderboard_atf', selectorId: 'infoPage'},
        { type: 'leaderboard_atf', selectorId: 'parkPage1'},
        { type: 'leaderboard_btf', selectorId: 'parkPage2'},
        { type: 'leaderboard_btf', selectorId: 'parkPage3'},
        { type: 'leaderboard_btf', selectorId: 'parkPage4'},
        { type: 'leaderboard_btf', selectorId: 'parkPage5'},
        { type: 'leaderboard_atf', selectorId: 'postsPage'},
        { type: 'leaderboard_atf', selectorId: 'standingsPage1'},
        { type: 'leaderboard_btf', selectorId: 'standingsPage2'},
        { type: 'leaderboard_atf', selectorId: 'teamsPage1'},
        { type: 'leaderboard_btf', selectorId: 'teamsPage2'},
        { type: 'leaderboard_atf', selectorId: 'leaguesByCityPage1'},
        { type: 'leaderboard_btf', selectorId: 'leaguesByCityPage2'},
        { type: 'leaderboard_btf', selectorId: 'leaguesByCityPage3'},
        { type: 'leaderboard_btf', selectorId: 'leaguesByCityPage4'},
        { type: 'leaderboard_btf', selectorId: 'leaguesByCityPage5'},
        { type: 'leaderboard_btf', selectorId: 'leaguesByCityPage6'},
        { type: 'leaderboard_btf', selectorId: 'leaguesByCityPage7'},
        { type: 'leaderboard_atf', selectorId: 'tournamentsByCityPage0'},
        { type: 'leaderboard_btf', selectorId: 'tournamentsByCityPage1'},
        { type: 'leaderboard_btf', selectorId: 'tournamentsByCityPage2'},
        { type: 'leaderboard_btf', selectorId: 'tournamentsByCityPage3'},
        { type: 'leaderboard_btf', selectorId: 'tournamentsByCityPage4'},
        { type: 'leaderboard_btf', selectorId: 'tournamentsByCityPage5'},
        { type: 'leaderboard_btf', selectorId: 'tournamentsByCityPage6'},
        { type: 'leaderboard_atf', selectorId: 'pwBlogInsert0'},
        { type: 'leaderboard_btf', selectorId: 'pwBlogInsert1'},
        { type: 'leaderboard_btf', selectorId: 'pwBlogInsert2'},
        { type: 'leaderboard_btf', selectorId: 'pwBlogInsert3'},
        { type: 'leaderboard_btf', selectorId: 'pwBlogInsert4'},
        { type: 'leaderboard_btf', selectorId: 'pwBlogInsert5'},
        { type: 'leaderboard_btf', selectorId: 'pwBlogInsert6'},
        { type: 'leaderboard_btf', selectorId: 'pwBlogInsert7'},
        { type: 'leaderboard_btf', selectorId: 'pwBlogInsert8'},
        { type: 'leaderboard_btf', selectorId: 'pwBlogInsert9'},
        { type: 'leaderboard_btf', selectorId: 'majorEvents0'},
        { type: 'leaderboard_btf', selectorId: 'majorEvents1'},
        { type: 'leaderboard_btf', selectorId: 'majorEvents2'},
        { type: 'leaderboard_btf', selectorId: 'majorEvents3'},
        { type: 'leaderboard_btf', selectorId: 'majorEvents4'},
        { type: 'leaderboard_btf', selectorId: 'majorEvents5'},
      ]"
      :PUB_ID="'1025306'"
      :WEBSITE_ID="'75041'"
    ></RampView>
  </ion-app>
</template>

<script setup>
import { IonApp, IonRouterOutlet, IonSplitPane, isPlatform } from "@ionic/vue";
import { defineComponent, watchEffect, ref, onMounted } from "vue";
import SideMenu from "@/components/drawer/SideMenu.vue";
import RampView from "./views/RampView.vue";

import { useRoute, useRouter } from "vue-router";
import { useLeagueStore } from "@/stores/leagueStore";
import { useGameStore } from "@/stores/gameStore";
import { usePreferenceStore } from "@/stores/preferenceStore";
import { useUIStateStore } from "@/stores/UIStateStore";
import { useNewGameStore } from "@/stores/newGameStore";

// import { rampOperations } from '@/utils/rampOperations';


import updateFiltersOnLoad from "@/utils/updateFiltersOnLoad";
import { debounce } from "lodash";
import { useHead } from "@unhead/vue";
import tinycolor from "tinycolor2";
import { openModal } from "@/utils/useIonicComponents.js";
import AddToHomeModal from "@/components/AddToHomeModal.vue";

defineComponent({
  name: "App",
});
useHead({
  title: "Slo-Pitch Central - Unleash Your League or Tournament Here",
  meta: [
    {
      name: "description",
      content:
        "Welcome to Slo-Pitch Central! Your free, one-stop platform for real-time scores, auto-updated standings, and more. Experience the ease of managing your league with our intelligent playoff placement and CSV schedule upload. Stay ahead of the game with our upcoming premium features. No logins, no hassle, just Slo-Pitch simplified!",
    },
    {
      name: "keywords",
      content:
        "Slo-Pitch, Central, League, Tournament, Real-time scores, Standings, Playoff, Schedule, Premium features",
    },
    {
      name: "og:title",
      content: "Slo-Pitch Central - Unleash Your League or Tournament Here",
    },
    {
      name: "og:description",
      content:
        "Welcome to Slo-Pitch Central! Your free, one-stop platform for real-time scores, auto-updated standings, and more. Experience the ease of managing your league with our intelligent playoff placement and CSV schedule upload. Stay ahead of the game with our upcoming premium features. No logins, no hassle, just Slo-Pitch simplified!",
    },
    {
      name: "og:image",
      content:
        "https://cdn.blacktiecollab.com/slopitchcentral/CleanShot 2023-07-04 at 01.19.41.png",
    },
    {
      name: "og:type",
      content: "website",
    },
    {
      name: "og:url",
      content: "https://slopitchcentral.com",
    },
    {
      name: "og:author",
      content: "Slo-Pitch Central - Unleash Your League or Tournament Here",
    },
  ],
});

const sideMenuBlacklist = ["home", "dashboard", "parkDetails"]; //these are route name found in router/index.js
const isIonSplitPaneDisabled = ref(true);
const route = useRoute();
const router = useRouter();

const gameStore = useGameStore();
const preferenceStore = usePreferenceStore();
const leagueStore = useLeagueStore();
const UIStateStore = useUIStateStore();
const newGameStore = useNewGameStore();

UIStateStore.checkOpenModals();

onMounted(() => {
  // addToHome();
  // rampOperations.reinitialize(route.path, '');
});

async function addToHome() {

    // setTimeout(() => {
      openModal(AddToHomeModal, "addToHomeModal");
    // }, 5000);
  
}

// if (
//     isPlatform("ios") &&
//     !window.navigator.standalone &&
//     !localStorage.getItem("a2hsModalDismissed")
//   ) {

if (route.params.leagueSlug) {
  // to trigger when there are no query params on first load but could be in preference store
  checkURLQueryFilters();
  useHead({
    title: `${leagueStore.leagueName} - Slo-Pitch Central`,
    meta: [
      {
        name: "description",
        content: `Get real-time scores, auto-updated standings, and more for ${leagueStore.leagueName} on Slo-Pitch Central.`,
      },
      {
        name: "keywords",
        content: `${leagueStore.leagueName}, Slo-Pitch, Central, League, Real-time scores, Standings`,
      },
      {
        name: "og:title",
        content: `${leagueStore.leagueName} - Slo-Pitch Central`,
      },
      {
        name: "og:description",
        content: `Get real-time scores, auto-updated standings, and more for ${leagueStore.leagueName} on Slo-Pitch Central.`,
      },
      {
        name: "og:url",
        content: `https://slopitchcentral.com/league/${leagueStore.leagueName}`,
      },
    ],
  });
}
function checkURLQueryFilters() {
  if (route.params.leagueSlug) {
    if (
      Object.keys(route.query).some((filter) =>
        Object.keys(gameStore.gameFilters).includes(filter)
      ) ||
      route.query.showReportedGames
    ) {
      console.log("Filters being loded from query");
      if (route.query.showReportedGames) {
        if (route.query.showReportedGames === "false") {
          gameStore.showReportedGames = false;
        } else {
          gameStore.showReportedGames = true;
        }
      }
      if (route.query.division_id) {
        gameStore.gameFilters.division_id = route.query.division_id;
      }
      if (route.query.park_name) {
        gameStore.gameFilters.park_name = route.query.park_name;
      }
      if (route.query.diamond_signifier) {
        gameStore.gameFilters.diamond_signifier = route.query.diamond_signifier;
      }
      if (route.query.team) {
        gameStore.gameFilters.team = route.query.team
          .split(",")
          .map((el) => Number(el));
      } else {
        gameStore.gameFilters.team = [null];
      }
      if (route.query.timeslot) {
        gameStore.gameFilters.start_time = route.query.timeslot;
      }
      if (route.query.month) {
        gameStore.currentShownDate = "all";
        gameStore.gameFilters.month = route.query.month;
      }
      if (route.query.day) {
        gameStore.currentShownDate = "all";
        gameStore.gameFilters.day = route.query.day;
      }
      if (route.query.showReportedGames) {
        preferenceStore.setFilters({
          ...gameStore.gameFilters,
          showReportedGames: gameStore.showReportedGames,
        });
      } else {
        preferenceStore.setFilters({ ...gameStore.gameFilters });
      }
    } else {
      console.log("No filters found, checking preference");
      updateFiltersOnLoad(route, router);
    }
  }
}

function resetColor() {
  window.document.body.style.setProperty("--ion-color-primary", "#7d38f0");
  window.document.body.style.setProperty("--ion-color-primary-tint", "#9d65f7");
  window.document.body.style.setProperty(
    "--ion-color-primary-shade",
    "#6e32cf"
  );
}

function setColor() {
  console.log("setting color");
  window.document.body.style.setProperty(
    "--ion-color-primary",
    leagueStore.league.primary_color
  );
  const c = tinycolor(leagueStore.league.primary_color);
  window.document.body.style.setProperty(
    "--ion-color-primary-tint",
    c.lighten().toString()
  );
  window.document.body.style.setProperty(
    "--ion-color-primary-shade",
    c.darken().toString()
  );
  window.document.body.style.setProperty(
    "--ion-color-secondary",
    leagueStore.league.secondary_color
  );
  const d = tinycolor(leagueStore.league.secondary_color);
  window.document.body.style.setProperty(
    "--ion-color-secondary-tint",
    d.lighten().toString()
  );
  window.document.body.style.setProperty(
    "--ion-color-secondary-shade",
    d.darken().toString()
  );
}

watchEffect(async () => {
  if (
    route.params.leagueSlug &&
    route.params.leagueSlug !== leagueStore.league.slug
  ) {
    //when league is switched with leagueswitchermodal
    gameStore.resetFilters();
    newGameStore.games.length = 0;

    await leagueStore.reloadEverything(route.params.leagueSlug);
    if (leagueStore.league.primary_color) {
      setColor();
    } else {
      resetColor();
    }
    useHead({
      title: `Schedule for ${leagueStore.leagueName} - Slo-Pitch Central`,
      meta: [
        {
          name: "description",
          content: `Check out the schedule for ${leagueStore.leagueName} on Slo-Pitch Central.`,
        },
        {
          name: "keywords",
          content: `${leagueStore.leagueName}, Slo-Pitch, Central, Schedule`,
        },
        {
          name: "og:title",
          content: `Schedule for ${leagueStore.leagueName} - Slo-Pitch Central`,
        },
        {
          name: "og:description",
          content: `Check out the schedule for ${leagueStore.leagueName} on Slo-Pitch Central.`,
        },
        {
          name: "og:url",
          content: `https://slopitchcentral.com/league/${leagueStore.leagueName}/dashboard`,
        },
      ],
    });
    updateFiltersOnLoad(route, router);
  } else if (
    router.options.history.state.back === "/" ||
    router.options.history.state.forward === "/"
  ) {
    // to check for filters when going back and forth from landingpage
    debounce(checkURLQueryFilters, 10)();
    if (leagueStore.league?.primary_color) {
      setColor();
    } else {
      resetColor();
    }
    useHead({
      title: `${leagueStore.leagueName} - Slo-Pitch Central`,
      meta: [
        {
          name: "description",
          content: `Get real-time scores, auto-updated standings, and more for ${leagueStore.leagueName} on Slo-Pitch Central.`,
        },
        {
          name: "keywords",
          content: `${leagueStore.leagueName}, Slo-Pitch, Central, League, Real-time scores, Standings`,
        },
        {
          name: "og:title",
          content: `${leagueStore.leagueName} - Slo-Pitch Central`,
        },
        {
          name: "og:description",
          content: `Get real-time scores, auto-updated standings, and more for ${leagueStore.leagueName} on Slo-Pitch Central.`,
        },
        {
          name: "og:url",
          content: `https://slopitchcentral.com/league/${leagueStore.leagueName}`,
        },
      ],
    });
  }

  if (!route.params.leagueSlug) {
    isIonSplitPaneDisabled.value = true;
    useHead({
      title: "Slo-Pitch Central - Unleash Your League or Tournament Here",
      meta: [
        {
          name: "description",
          content:
            "Welcome to Slo-Pitch Central! Your free, one-stop platform for real-time scores, auto-updated standings, and more. Experience the ease of managing your league with our intelligent playoff placement and CSV schedule upload. Stay ahead of the game with our upcoming premium features. No logins, no hassle, just Slo-Pitch simplified!",
        },
        {
          name: "keywords",
          content:
            "Slo-Pitch, Central, League, Tournament, Real-time scores, Standings, Playoff, Schedule, Premium features",
        },
        {
          name: "og:title",
          content: "Slo-Pitch Central - Unleash Your League or Tournament Here",
        },
        {
          name: "og:description",
          content:
            "Welcome to Slo-Pitch Central! Your free, one-stop platform for real-time scores, auto-updated standings, and more. Experience the ease of managing your league with our intelligent playoff placement and CSV schedule upload. Stay ahead of the game with our upcoming premium features. No logins, no hassle, just Slo-Pitch simplified!",
        },
        {
          name: "og:image",
          content:
            "https://cdn.blacktiecollab.com/slopitchcentral/CleanShot 2023-07-04 at 01.19.41.png",
        },
        {
          name: "og:type",
          content: "website",
        },
        {
          name: "og:url",
          content: "https://slopitchcentral.com",
        },
        {
          name: "og:author",
          content: "Slo-Pitch Central - Unleash Your League or Tournament Here",
        },
      ],
    });
    resetColor();
  }
});
</script>
<style>
ion-datetime {
  color: var(--ion-text-color);
}
.selectModal,
.addGameModal,
.appearanceModal,
.newAnnouncementModal,
.uploadScheduleModal,
.uploadScheduleModalv2 {
  --width: 100%;
  --height: 100%;
  --border-radius: 0%;
}

ion-split-pane {
  --side-width: 310px;
  --side-max-width: 310px;
  margin-top: 30px;
}

.something-above {
  text-align: center;
  text-transform: uppercase;
  line-height: 25px;
  cursor: pointer;
  font-weight: bold;
  font-size: 11px;
  letter-spacing: 1px;
  background: #301760;
}

.custom-toast {
  transform: translateY(-80px);
}
.custom-toast::part(message) {
  text-align: center !important;
}

</style>
